<template>
    <component
        :is="navigationComponent"
        :entries="navigation"
        :settings="settings"
        :has-banner="hasBannerImage"
        :home-uri="homeUri"
        :show-search="!isMicrosite"
    >
        <template #logo="navigationProps">
            <img v-if="navigationProps.sticky" :src="stickyHeaderLogo[0]?.url ?? headerLogo[0]?.url" :alt="`${variables?.siteName} logo`" :class="stickyLogoSize">
            <img v-else :src="headerLogo[0]?.url" :alt="`${variables?.siteName} logo`" :class="logoSize">
        </template>
    </component>
</template>

<script setup>
    const pageType = inject('page-type', 'home')

    const {
        homeUri,
        navigation,
        globalSets,
        digistormSettings,
        variables,
        getGlobalSetField,
        isMicrosite,
    } = storeToRefs(useSiteStore())

    const settings = computed(() => {
        const navigationSet = find(globalSets.value, { __typename: 'ds_Navigation_GlobalSet'})

        return {
            navigationType: digistormSettings.value.navType,
            ...Object.entries(navigationSet).reduce((acc, [key, value]) => {
                acc[key] = getGlobalSetField.value('ds_Navigation_GlobalSet', key)
                return acc
            }, {})
        }
    })

    const hasBannerImage = computed(() => {
        // we don't have access to the banner data at this level anymore.
        // it's save to assume all homepages have a banner
        return true
    })

    const navigationComponent = defineAsyncComponent(() =>
        import(`./${pageType}/${settings.value.navigationType}/nav.async.vue`)
    )

    const headerLogo = computed(() => {
        return getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_HeaderLogo', true)
    })

    const logoSize = computed(() => {
        return logoScale(getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_HeaderLogoSize', true))
    })

    const stickyHeaderLogo = computed(() => {
        return getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_StickyNavLogo', true)
    })

    const stickyLogoSize = computed(() => {
        return logoScale(getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_StickyNavLogoSize', true))
    })

    const logoScale = (size) => {
        switch(size) {
            case 'sm':
                return 'scale-50'
            case 'lg':
                return 'scale-150'
            case 'md':
            default:
                return ''
        }
    }

</script>

<style lang="scss" scoped>

</style>
