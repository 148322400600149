import revive_payload_client_wL0DMxsZiw from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hy0aJn7bB5 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mGO2oGTC77 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yOP5HRmakj from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_sass@1.85.0__lrngvuejlwbtejpkruhr4v4wka/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_i7KHMXczSi from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eD98YcL06u from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1eCtQS89ko from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1fpnD8ztuF from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-frontend/web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_cPvz97RdKP from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_n46bqqxyy33adnqkqa5xic4x5a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_lcDPsOIxdJ from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_jHonLTUQqo from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_RYhFRgzRQ8 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_9bxHmndeAa from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.34.8_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_craft_sclci9JHWi from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-craft.ts";
import apollo_error_handler_a0IiUcX0mN from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-error-handler.ts";
import crypto_IPXw5s4rs8 from "/home/runner/work/web-frontend/web-frontend/plugins/crypto.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/web-frontend/web-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_wL0DMxsZiw,
  unhead_hy0aJn7bB5,
  router_mGO2oGTC77,
  _0_siteConfig_yOP5HRmakj,
  payload_client_i7KHMXczSi,
  navigation_repaint_client_eD98YcL06u,
  check_outdated_build_client_1eCtQS89ko,
  chunk_reload_client_1fpnD8ztuF,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cPvz97RdKP,
  switch_locale_path_ssr_lcDPsOIxdJ,
  i18n_jHonLTUQqo,
  plugin_RYhFRgzRQ8,
  plugin_9bxHmndeAa,
  apollo_craft_sclci9JHWi,
  apollo_error_handler_a0IiUcX0mN,
  crypto_IPXw5s4rs8,
  sentry_client_shVUlIjFLk
]