export const ds_NewsletterArticle_Entry = `
    fragment ds_NewsletterArticle_Entry on ds_NewsletterArticle_Entry {
        id
        canonicalUid
        title
        slug
        dateCreated
        image {
            url @transform(width: 720, height: 405)
            url_mobile: url @transform(width: 350, height: 240)
        }

        articleContentBuilder {
            __typename
        }
    }
`