<template>
    <component :is="footerComponent" :social-links="socialLinks" :entry="footer">
        <template #logo>
            <img v-if="footerLogo.length > 0" :src="footerLogo[0].url" class="h-full" :class="logoSize">
        </template>

        <template #siteByDigistorm>
            <NuxtLink to="http://www.digistorm.com.au" class="font-medium text-brand-extra-strong hover:underline underline-offset-2 text-sm" target="_blank">
                site by Digistorm
            </NuxtLink>
        </template>

        <template #copyright>
            <span class="font-medium text-brand-extra-strong text-sm">
                © {{currentYear}} {{variables?.siteName}}
            </span>
        </template>
    </component>
</template>

<script setup>
    const { digistormSettings, variables, getGlobalSetField, appId, microsite, isMicrosite } = storeToRefs(useSiteStore())

    const footer = ref(null)
    const query = ref()

    const footerType = computed(() => {
        return digistormSettings.value.footerType
    })

    // check for any custom components
    const customComponents = await import(`~/custom/${appId.value}/index.js`)
        .catch(() => {
            console.log('no custom components footer')
        })

    console.log('customComponents -a ', appId.value)

    if (customComponents?.default && footerType.value === 'footer-custom') {
        console.log('customComponents', customComponents?.default)
        query.value = customComponents?.default?.FooterQuery
    } else {
        // check the footerType and dynamically import the query from queries/footers/{footerType}.ts
        query.value  = await import(`~/queries/footers/${footerType.value}.ts`).then((q) => {
            return q.default
        })
            .catch(async () => {
                return (await import('~/queries/footers/Footer.ts')).default
            })

    }

    const socialLinks = computed(() => {
        return getGlobalSetField.value('ds_Socials_GlobalSet', 'socials_SocialLinks')
    })

    const footerComponent = computed(() => {
        if (footerType.value === 'footer-custom') {
            return customComponents?.default?.Footer
        }

        return defineAsyncComponent(() =>
            import(`./${footerType.value}/footer.async.vue`)
        )
    })

    const footerLogo = computed(() => {
        return getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_FooterLogo', true)
    })

    const logoSize = computed(() => {
        switch(getGlobalSetField.value('ds_Logos_GlobalSet', 'logos_FooterLogoSize', true)) {
            case 'sm':
                return 'max-h-18 max-w-48'
            case 'lg':
                return 'max-h-54 max-w-144'
            case 'md':
            default:
                return 'max-h-36 max-w-96'
        }
    })

    const currentYear = computed(() => {
        return new Date().getFullYear()
    })

    const getFooterData = async () => {
        if (!query.value) {
            return []
        }

        const footerQuery =  isMicrosite.value ? `
            query footerContent {
                entry(uid: "${microsite.value.canonicalUid}") {
                    ... on ds_MicrositeHomepage_Entry {
                        footer {
                            ${query.value}
                        }
                    }
                }
            }` : `
            query footerContent {
                globalSets {
                    ... on ds_Navigation_GlobalSet {
                        footer {
                            ${query.value}
                        }
                    }
                }
            }`

        console.log('footer query', footerQuery)

        const { data, error } = await useAsyncQuery({
            query: gql`${footerQuery}`,
            cache: false,
        })

        if (!isEmpty(error.value)) {
            console.log('custom query failed', error.value)
        }

        footer.value = isMicrosite.value 
            ? get(data.value, 'entry.footer.0', [])
            : get(data.value, 'globalSets.0.footer.0', [])
    }

    await getFooterData()
</script>

<style lang="scss" scoped>

</style>
