// entryQuery gets replaced at runtime
export const articleQuery = `
    query article($slug: String) {
        entry(slug: [$slug]) {
            __typename
            id
            canonicalUid
            title
            uri
            slug

            [entryQuery]

            seomatic(asArray: true) {
                metaJsonLdContainer
                metaLinkContainer
                metaScriptContainer
                metaSiteVarsContainer
                metaTagContainer
                metaTitleContainer
            }
        }
    }
`