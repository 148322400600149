export const ds_Newsletter_Entry = `
    fragment ds_Newsletter_Entry on ds_Newsletter_Entry {
        id
        canonicalUid
        title
        uri
        dateCreated
        featuredArticleImagePosition
        featuredArticles
        featuredArticlesPalette
        articleType
        numberOfColumnsDesktop
        mobileThumbnailsDisplay
        category {
            id
            title
        }
        highlightArticle {
            ... on ds_NewsletterArticle_Entry {
                id
                canonicalUid
                title
                dateCreated
                articleExcerpt
                slug
                image {
                    url @transform(width: 720, height: 405)
                    url_mobile: url @transform(width: 350, height: 240)
                }
                articleContentBuilder {
                    __typename
                }
            }
        }
        newsletterSections {
            ... on ds_NewsletterSection_Entry {
                id
                newsletterSection_Heading
                newsletterSection_Articles {
                    ... on ds_NewsletterArticle_Entry {
                        id
                        canonicalUid
                        title
                        dateCreated
                        articleExcerpt
                        slug
                        image {
                            url @transform(width: 720, height: 405)
                            url_mobile: url @transform(width: 350, height: 240)
                        }
                        articleContentBuilder {
                            __typename
                        }
                    }
                }
            }
        }
    }
`