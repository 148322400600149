<template>
    <NuxtLayout>
        <NuxtLoadingIndicator />
        <NuxtPage />

        <AlertBar v-if="alert && !isMicrosite" />
    </NuxtLayout>
</template>

<script setup>
    const cookieCredentials = useCookie('cms_credentials')

    const { apiUrl, adminUrl, craftUrl, globalSets, themeCss, isMicrosite } = storeToRefs(useSiteStore())

    const alert = computed(() => {
        return find(globalSets.value, { name: 'Alert bar' })
    })

    const addFavicon = () => {
        const logos = find(globalSets.value, { name: 'Logos'})
        const favicon = get(logos, 'logos_BrowserIcon[0].url')
        if (!favicon) {
            return
        }
        useHead({
            htmlAttrs: {
                lang: 'en'
            },
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-icon',
                    href: favicon
                }
            ]
        })
    }

    const addGlobalCode = () => {
        const globalCode = find(globalSets.value, { __typename: 'ds_GlobalCode_GlobalSet' })
        const headCode = get(globalCode, 'globalCode_HeadCode')
        const bodyCode = get(globalCode, 'globalCode_BodyCode')

        if (headCode) {
            const headElement = document.createElement('div')
            headElement.id = 'global-head-code'
            headElement.innerHTML = headCode
            document.head.appendChild(headElement)

            const scripts = []

            // Extract all scripts from the head code
            const headScripts = headElement.getElementsByTagName('script')
            for (const script of headScripts) {
                if (script.src) {
                    scripts.push({
                        src: script.src ?? '',
                        type: script.type || 'text/javascript',
                    })
                } else {
                    scripts.push({
                        innerHTML: script.textContent ?? '',
                        type: script.type || 'text/javascript',
                    })
                }

            }

            useHead({
                script: scripts
            })
        }

        if (bodyCode) {
            const bodyElement = document.createElement('div')
            bodyElement.id = 'global-body-code'
            bodyElement.innerHTML = bodyCode
            document.body.appendChild(bodyElement)

            // Extract and execute any script tags in the body code
            const bodyScripts = bodyElement.getElementsByTagName('script')
            for (const script of bodyScripts) {
                const newScript = document.createElement('script')
                newScript.type = script.type || 'text/javascript'
                if (script.src) {
                    newScript.src = script.src
                } else {
                    newScript.textContent = script.textContent
                }
                document.body.appendChild(newScript)
            }
        }

    }


    onMounted(() => {
        const apiEndpoint = cookieCredentials.value?.endpoint
        apiUrl.value = apiEndpoint

        adminUrl.value = replace(apiEndpoint, '/api', '/admin')
        craftUrl.value = replace(apiEndpoint, '/api', '')

        addFavicon()
        addGlobalCode()
    })

    each(themeCss.value, (styles) => {
        useHead({
            style: [
                {
                    innerHTML: styles.css,
                    id: `site-theme-${styles.id}`,
                },
            ]
        })
    })

</script>