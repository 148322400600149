import { ds_MicrositeHomepage_Partial } from '../shared/ds_MicrositeHomepage_Partial'

export const ds_MicrositeHomepage_Entry = `
    fragment ds_MicrositeHomepage_Entry on ds_MicrositeHomepage_Entry {
        ...ds_MicrositeHomepage_Partial

        canonicalUid
        typeHandle
        hideBanner
        popup {
            canonicalUid
        }
        preFooter {
            canonicalUid
        }
        contentBuilder {
            __typename
        }
    }
    ${ds_MicrositeHomepage_Partial}
`
